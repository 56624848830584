import React from "react"
import Layout from "../components/layout.js"
import * as styles from "./hmm.module.css"
import Meta from "../components/meta.js"
import Footer from "../components/footer.js"
export default function about() {
  return (
    <div>
      <div>
        <Meta />
        <Layout></Layout>
        <div className={styles.sidebanner}></div>
        <div
          style={{
            margin: `3rem auto`,
            padding: `0 1rem`,
            maxWidth: `1000px`,
            display: `flex`,
            flexWrap: `wrap`,
          }}
        >
          <h1>
            <mark
              style={{ fontWeight: `700`, fontSize: `3rem`, marginTop: `2rem` }}
            >
              About
            </mark>
          </h1>
          <div className={styles.aboutbody}>
            <p
              style={{
                fontSize: `1.2rem`,
                padding: `20px`,
              }}
            >
              Instituted in 2008 by the students of Delhi Public School Dwarka,{" "}
              <mark>COЯE </mark>
              <span> </span>was established with the aim of promoting competence
              and excellence in the field of computer and information technology
              at school, as well as various inter-school symposia. COЯE hosted
              its first Inter School Symposium in 2008. Since then, we have not
              looked back.
              <br />
              <br />
              Over the years, the club has progressed phenomenally, dominating
              several competitions and attaining victory. We have set high
              standards and ensure that we not only conform to these year after
              year, but set the bar a few notches higher. It takes a radical
              attitude, love for computers and an exceptional set of skills to
              be a part of the club.
              <br />
              <br />
              COЯE 15 was held on 7<sup>th</sup>-15<sup>th</sup> October 2022.
              Certainly one of the best symposiums last year, it consisted of
              various events like Programming, Web Design, Gaming, Cryptic Hunt etc. The
              overall trophy was bagged by AISG46.
            </p>
          </div>
        </div>{" "}
      </div>
      <Footer></Footer>
    </div>
  )
}
